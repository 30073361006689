<template>
    <router-view></router-view>
</template>

<script>

export default {
  name: 'App',
}
</script>

<style>
#app {
    width: 100%;
    -webkit-touch-callout: none !important;
    -webkit-user-select: none;
}
</style>
