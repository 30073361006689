window._$api = {
  proUrl: 'https://api.kelemetaverse.com/', // 生产环境 请求URL IP 端口号
  devUrl: 'https://api.kelemetaverse.com/', // 开发环境 请求URL IP 端口号
  devIconUrl: ''// 开发环境 请求图片URL IP 端口号
}

function getUrl() {
  if (window._$api.proUrl.length) return
  window._$api['proUrl'] = window.location.href.split('/#')[0]
}
getUrl()
