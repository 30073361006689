export default {
    share: {
        title: 'Kele Jump',
        content: {
            one: "Kele Jump is a web3 somatosensory application integrating physical strength and intellectual challenge.Users can control the jumping distance of chess piece by squatting posture, which requires good physical strength and flexible control.",
            two: "In addition to exercising while playing alone, you can also invite multiple friends to participate in your challenge and compete to get better records."
        },
        download: "Download",
        tips:"Tips",
        tipsContent:"The IOS version is still being worken on,so stay tuned!",
        online:"Play Online"
    },
    collectLikes: {
        prompt: {
            one: "There is still",
            two: "hours until the end!",
            timeout: "Sharing ended!"
        },
        content: {
            one: "Click the unopened circle to help your friend resurrection.",
            two: "Every 3 circle friends open increases help seeker chance of resurrection by 1."
        },
        btn: "Download",
        dialogContent: {
            one: "Thank you for helping your friends succeed!",
            two: "Jump with friends!",
            timeout: "Sharing ended!"
        },
        dialogBtns: {
            confirm: "Have a try",
            refused: ""
        }
    },
    tetris:{
        share:{
            title:"Kele Tetris",
            content: {
                one: "Kele Tetris is a web3 somatosensory application integrating physical strength and intellectual challenge.There are no boring sports, only fun games. Control the shape and direction of the Tetris by waving your arms and squatting. Swing your arms left and right, and the Tetris moves left and right; raise your arms above your head, and the Tetris changes shape; squat down, and the Tetris falls quickly.",
                two: "In addition to exercising while playing alone, you can also invite multiple friends to participate in your challenge and compete to get better records."
            },
        },
        collectLikes: {
            prompt: {
                one: "There is still",
                two: "hours until the end!",
                timeout: "Sharing ended!"
            },
            content: {
                one: "Click the unopened circle to help your friend resurrection.",
                two: "Every 3 circle friends open increases help seeker chance of resurrection by 1."
            },
            btn: "Download",
            dialogContent: {
                one: "Thank you for helping your friends succeed!",
                two: "Exercise with friends!",
                timeout: "Sharing ended!"
            },
            dialogBtns: {
                confirm: "Have a try",
                refused: ""
            }
        },
    },
    touchball:{
        share: {
            title: "Kele TouchBallBall",
            content: {
                one: "\"Kele TouchBallBall\" is a Web3 sensory application that combines fitness and entertainment. By swinging your arm and touching the balls on the screen in numerical order, you can challenge both your physical and mental abilities without the boredom of traditional exercise. The operation is simple and you can start the game and fitness journey anytime, anywhere by just leaning your phone or tablet against a stationary object. You can lose weight easily and happily in the game by thinning your arms and thighs! You can also redeem game points for lottery draws and draw lucky cards. Share your achievements and check the world rankings, and invite friends to enjoy the fun of entertainment and fitness together. Please note that when playing the game, please do what you can according to your actual physical condition and do not get injured.",
                two: "In addition to exercising while playing alone, you can also invite multiple friends to participate in your challenge and compete to get better records."
            },
        },
        card:{
            title:"Rabbit Card",
            subtitle:"Collect rabbit cards and get blessings",
            score:"Score",
            card:"Card",
            notice:{
                "text":"Congratulations on completing the Rabbit Cards and wishing you a Happy New Year.",
                "close":"Waiting for the drawing"
            },
            nocard: "Card not selected",
            cancel: "Cancel",
            share: "Share Card",
            give: "Give Card",
            giveError:"Give Card Error"
        }

    },
    tapdance: {
        share: {
            title: "Kele Tap Dance",
            content: {
                one: "Kele Tetris is a web3 somatosensory application integrating physical strength and intellectual challenge.There are no boring sports, only fun games.Kick away worries, anxiety, pain, and unhappiness. Simply place your phone or tablet device in front of you to start a journey of gaming and fitness. Say goodbye to boring exercise and enjoy the fun of fitness while playing games.",
                two: "In addition to exercising while playing alone, you can also invite multiple friends to participate in your challenge and compete to get better records."
            },
        },
        collectLikes: {
            prompt: {
                one: "There is still",
                two: "hours until the end!",
                timeout: "Sharing ended!"
            },
            content: {
                one: "Click the unopened circle to help your friend resurrection.",
                two: "Every 3 circle friends open increases help seeker chance of resurrection by 1."
            },
            btn: "Download",
            dialogContent: {
                one: "Thank you for helping your friends succeed!",
                two: "Exercise with friends!",
                timeout: "Sharing ended!"
            },
            dialogBtns: {
                confirm: "Have a try",
                refused: ""
            }
        },
    },
    cutwood: {
        share: {
            title: "Kele Cut Wood",
            content: {
                one: "Kele Cut Wood is a Web3 somatosensory application that combines physical and intellectual challenges.",
                two: "Welcome to the Cut Wood game! In this game, you will play as a boxer and use punching actions to chop down the wood. You need to complete the game's tasks by using a series of actions, such as hook punches and jabs. The game will detect your movements through somatosensory technology, and only when your movements meet the requirements can you chop down the wood. You can control the direction of the lumberjack's chopping by using your left and right fists, and if the lumberjack touches a branch, the game will fail.In the Cut Wood game, you will experience the thrill of boxing and the sense of accomplishment from chopping down the wood, which will also help you exercise your body while playing.Come and experience this unique somatosensory game and challenge your limits!"
            },
        },
        collectLikes: {
            prompt: {
                one: "There is still",
                two: "hours until the end!",
                timeout: "Sharing ended!"
            },
            content: {
                one: "Click the unopened circle to help your friend resurrection.",
                two: "Every 3 circle friends open increases help seeker chance of resurrection by 1."
            },
            btn: "Download",
            dialogContent: {
                one: "Thank you for helping your friends succeed!",
                two: "Exercise with friends!",
                timeout: "Sharing ended!"
            },
            dialogBtns: {
                confirm: "Have a try",
                refused: ""
            }
        },
    },
};