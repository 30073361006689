export default {
    share: {
        title: '可乐跳一跳',
        content:{
            one: "可乐跳一跳是一款集体力与智力挑战于一体的Web3体感应用。可乐跳一跳操作简单，又能够较好的锻炼使用者的体力、耐力和控制力。你可以通过深蹲时间的长短来控制棋子跳跃距离的远近。",
            two:"除了单人训练，你还可以邀请更多的好友一起来参与多人挑战赛和团队挑战赛，争夺更好的成绩。"
        },
        download:"下载",
        tips: "提示",
        tipsContent: "IOS版本仍在开发中，敬请关注！",
        online:"在线体验"
    },
    collectLikes: {
        prompt: {
            one: "距离分享活动结束时间还有",
            two: "小时！",
            timeout: "分享已结束！"
        },
        content: {
            one: "点击还未打开的圆圈来帮助你的朋友复活。",
            two: "每点开三个圆圈就能给请求帮助的人增加一次复活机会。"
        },
        btn: "下载",
        dialogContent: {
            one: "感谢你，已为好友助力成功！",
            two: "和朋友一起跳一跳吧！！",
            timeout: "分享已结束！"
        },
        dialogBtns: {
            confirm: "下载试试",
            refused: "残忍拒绝"
        }
    },
    tetris: {
        share: {
            title: "可乐俄罗斯方块",
             
            content: {
                one: "可乐俄罗斯方块是一款集体力与智力挑战于一体的Web3体感应用。没有枯燥的运动，只有好玩儿的游戏。通过挥动手臂与下蹲动作控制方块的形状与方向。左右挥动手臂，方块左右移动；双臂举过头顶，方块变换形状；下蹲，方块快速下落。",
                two: "除了单人训练，你还可以邀请更多的好友一起来参与多人挑战赛和团队挑战赛，争夺更好的成绩。"
            },
        },
        collectLikes: {
            prompt: {
                one: "距离分享活动结束时间还有",
                two: "小时！",
                timeout: "分享已结束！"
            },
            content: {
                one: "点击还未打开的圆圈来帮助你的朋友复活。",
                two: "每点开三个圆圈就能给请求帮助的人增加一次复活机会。"
            },
            btn: "下载",
            dialogContent: {
                one: "感谢你，已为好友助力成功！",
                two: "和朋友一起运动一下吧！！",
                timeout: "分享已结束！"
            },
            dialogBtns: {
                confirm: "下载试试",
            }
        }
        
    },
    touchball: {
        share: {
            title: "可乐摸摸球",
            content: {
                one: "\"可乐摸摸球\"是一款兼具健身与娱乐功能的Web3体感应用。通过挥动手臂，按照数字顺序触摸屏幕中的球，在没有枯燥的运动的同时进行智力挑战。使用简单，随时随地可以开始游戏。通过玩游戏瘦身，瘦手臂、瘦大腿，在游戏中轻松快乐减肥! 你还可以通过游戏积分兑换抽奖机会，获得幸运福卡。分享战绩，查看世界排名，邀请好友共同享受娱乐与健身的乐趣。请注意，玩游戏时请根据自己的身体状况量力而行，避免受伤。",
                two: "In addition to exercising while playing alone, you can also invite multiple friends to participate in your challenge and compete to get better records."
            },
        },
        card: {
            title: "我的兔卡",
            subtitle: "集兔卡，聚福气",
            score: "积分",
            card: "兔卡",
            notice: {
                "text": "祝贺您完成集兔卡，祝愿您新的一年，前兔似锦。",
                "close": "坐等开奖"
            },
            nocard: "未抽中卡片",
            cancel:"取消",
            share:"分享兔卡",
            give:"赠送兔卡",
            giveError: "生成赠送卡片失败"
        }
    },
    tapdance: {
        share: {
            title: "可乐踢踏舞",

            content: {
                one: "可乐踢踏舞是一款集体力与智力挑战于一体的Web3体感应用。踢走烦恼、踢走焦虑、踢走痛苦、踢走不开心。只需将手机或平板设备放在您面前，即可开启游戏与健身之旅。告别无聊的锻炼，在玩儿游戏的同时享受健身乐趣。",
                two: "除了单人训练，你还可以邀请更多的好友一起来参与多人挑战赛和团队挑战赛，争夺更好的成绩。"
            },
        },
        collectLikes: {
            prompt: {
                one: "距离分享活动结束时间还有",
                two: "小时！",
                timeout: "分享已结束！"
            },
            content: {
                one: "点击还未打开的圆圈来帮助你的朋友复活。",
                two: "每点开三个圆圈就能给请求帮助的人增加一次复活机会。"
            },
            btn: "下载",
            dialogContent: {
                one: "感谢你，已为好友助力成功！",
                two: "和朋友一起运动一下吧！！",
                timeout: "分享已结束！"
            },
            dialogBtns: {
                confirm: "下载试试",
            }
        }

    },
    cutwood: {
        share: {
            title: "可乐砍木头",

            content: {
                one: "可乐砍木头是一款集体力与智力挑战于一体的Web3体感应用。",
                two: "欢迎来到砍木头游戏！在这个游戏中，你将扮演一位拳击手，通过拳击动作来砍伐木头。你需要通过勾拳、摆拳等一系列动作来完成游戏中的任务。游戏会通过体感技术来检测你的动作，只有当你的动作符合要求时，才能砍倒木头。你可以通过左右拳控制伐木工的伐木方向，若伐木工触碰到树枝，则游戏失败。在砍木头游戏中，你将感受到拳击的快感和砍木头的成就感，让你在玩游戏的同时也锻炼了身体。快来体验这款独特的体感游戏，挑战自己的极限吧！"
            },
        },
        collectLikes: {
            prompt: {
                one: "距离分享活动结束时间还有",
                two: "小时！",
                timeout: "分享已结束！"
            },
            content: {
                one: "点击还未打开的圆圈来帮助你的朋友复活。",
                two: "每点开三个圆圈就能给请求帮助的人增加一次复活机会。"
            },
            btn: "下载",
            dialogContent: {
                one: "感谢你，已为好友助力成功！",
                two: "和朋友一起运动一下吧！！",
                timeout: "分享已结束！"
            },
            dialogBtns: {
                confirm: "下载试试",
            }
        }

    },
};