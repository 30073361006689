import { createI18n } from 'vue-i18n';
import ZH from './zh.js';
import EN from './en.js';
const messages = {
    zh: { ...ZH },
    en: { ...EN },
};

const getDefaultLang = () => {
    //用户未指定时，根据游览器选择:
    if (navigator.language == 'zh-CN') {
        return 'zh';
    } else if (navigator.language == 'en') {
        return 'en';
    } else {
        return 'en';
    }
}

const i18n = createI18n({
    legacy: false,
    globalInjection: true,
    locale: getDefaultLang(),
    messages
});
export default i18n;