import { createApp } from 'vue'
import App from './App.vue'
import i18n from './language/index';
// import 'lib-flexible/flexible'
import '@/assets/js/rem'
import '@/api/apiHeader.js' // api header

// 加载Vant核心组件库
import Vant from 'vant'
// 加载Vant全局样式
import 'vant/lib/index.css'
import routers from './router'
import { Dialog } from 'vant';
import { Toast } from 'vant';

const app = createApp(App);
app.use(Vant)
app.use(Dialog)
app.use(routers)
app.use(i18n)
app.use(Toast)

app.mount('#app')

// 局部注册
export default {
    components: {
        [Dialog.Component.name]: Dialog.Component,
    },
};