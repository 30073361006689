const baseSize = 32
// 设置rem函数
function setRem () {
    // 当前页面宽度相对于750宽的缩放比例,可根据自己需要修改
    const scale = document.documentElement.clientWidth / 750
    console.log(scale)
    // 设置页面根节点字体大小
    document.documentElement.style.fontSize = (baseSize * Math.min(scale, 2)) + 'px'
    // window.location.reload()
}

// 初始化
setRem()

//  改变窗口大小时重新设置rem
window.onresize = function () {
    setRem()
    window.location.reload()
}