import { createRouter, createWebHashHistory } from 'vue-router'

const routers = [
    {
        path: '/home',
        name: 'home',
        component: () => import('./components/HelloWorld')
    },
    {
        path: '/',
        redirect: '/jump/download',
        // component: () => import("./pages/game/index.vue")
    },
    {
        name:"jump",
        path: "/jump",
        // component: () => import("./pages/game/index.vue")
        redirect: '/jump/download',
        children:[
            {
                path: 'download',
                name: 'download',
                component: () => import('./pages/jump/download/index.vue')
            }
        ]
    },
    {
        name: "collectLikes",
        path: "/jump/collectLikes",
        component: () => import('./pages/jump/collect/index.vue')
    },
    {
        name: "tetris",
        path: "/tetris/download",
        component: () => import('./pages/tetris/download/index.vue')
    },
    {
        name: "tetris/CollectLikes",
        path: "/tetris/collectLikes",
        component: () => import('./pages/tetris/collect/index.vue')
    },
    {
        name: "touchballball",
        path: "/touchballball/download",
        component: () => import('./pages/touchballball/download/index.vue')
    },
    {
        name: "touchballball/card",
        path: "/touchballball/card",
        component: () => import('./pages/touchballball/card/index.vue')
    },
    {
        name: "touchballball/CollectLikes",
        path: "/touchballball/collectLikes",
        component: () => import('./pages/touchballball/collect/index.vue')
    },
    {
        name: "tapdance",
        path: "/tapdance/download",
        component: () => import('./pages/tapdance/download/index.vue')
    },
    {
        name: "tapdance/CollectLikes",
        path: "/tapdance/CollectLikes",
        component: () => import('./pages/touchballball/collect/index.vue')
    },
    {
        name: "cutwood",
        path: "/cutwood/download",
        component: () => import('./pages/cutwood/download/index.vue')
    },
    {
        name: "cutwood/CollectLikes",
        path: "/cutwood/CollectLikes",
        component: () => import('./pages/cutwood/collect/index.vue')
    },
]

const router = createRouter({
    history: createWebHashHistory(),
    routes: routers
})
export default router